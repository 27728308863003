const filter = require('lodash/filter')
const flatMap = require('lodash/flatMap')
const parse = require('./date_time').parse
const isBeforeRaw = require('date-fns/isBefore')
const sortActivities = require('./sort').sortActivities

const isBefore =
  typeof isBeforeRaw === 'object' ? isBeforeRaw.default : isBeforeRaw

const isPublic = (activity) => !!activity.public

const enrichActivity = (event) => (activity) => {
  let newActivity = {}
  newActivity.event = event
  const dt = activity.date_time
  const cdt = activity.created_date_time
  const parsed_dt = parse(dt)
  const parsed_cdt = parse(cdt)
  if (parsed_dt.toString() == 'Invalid Date')
    throw new Error(`Invalid Date: ${dt} - ${parsed_dt}`)
  if (parsed_cdt.toString() == 'Invalid Date')
    throw new Error(`Invalid Date: ${cdt} - ${parsed_cdt}`)
  newActivity.date_time = parsed_dt
  if (cdt) newActivity.created_date_time = parsed_cdt
  return Object.assign({}, activity, newActivity)
}

const toActivities = (events) =>
  sortActivities(
    filter(
      flatMap(events, (event) => event.activities.map(enrichActivity(event))),
      isPublic
    )
  )

const toPrivateActivities = (events) =>
  sortActivities(
    flatMap(events, (event) => event.activities.map(enrichActivity(event)))
  )

const splitPastFuture = (events, filter) => {
  const now = new Date()

  return (filter ? events.filter(filter) : events).reduce(
    (acc, event) => {
      const date_time = parse(sortActivities(event.activities)[0].date_time)
      if (isBefore(date_time, now)) acc.past.push(event)
      else acc.future.push(event)

      return acc
    },
    { past: [], future: [] }
  )
}

module.exports = {
  toActivities: toActivities,
  toPrivateActivities: toPrivateActivities,
  splitPastFuture: splitPastFuture,
}
