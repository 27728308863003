const parse = require('./date_time').parse

const sortEvents = (events) =>
  events.slice(0).sort((e1, e2) => {
    return (
      parse(sortActivities(e1.activities)[0].date_time) -
      parse(sortActivities(e2.activities)[0].date_time)
    )
  })

const sortActivities = (activities) =>
  activities.slice(0).sort((a1, a2) => {
    const date_time1 = parse(a1.date_time)
    const date_time2 = parse(a2.date_time)
    if (date_time1 > date_time2) return 1
    else if (date_time2 > date_time1) return -1
    else return 0
  })

module.exports = {
  sortEvents: sortEvents,
  sortActivities: sortActivities,
}
